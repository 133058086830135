import { jsxs as S, jsx as a } from "react/jsx-runtime";
import k from "classnames";
import C, { useMemo as N } from "react";
import "uuid";
import "@jetbrains/ring-ui-built/components/global/theme.js";
import "../Theme/ThemeContext.js";
import { useEnlargementCoefficient as b } from "../../hooks/useEnlargementCoefficient.js";
import d from "./Skeleton.module.css.js";
const l = 200, O = C.memo((r) => {
  const {
    circle: i = !1,
    className: c = "",
    innerRadius: u,
    outerRadius: m,
    width: h,
    height: f
  } = r, p = b(), g = (t, x = p) => t != null ? t * x : void 0, [n, e, s, o] = [
    u,
    m,
    h,
    f
  ].map((t) => g(t)), R = i ? {
    borderRadius: "50%",
    height: e ? e * 2 : void 0,
    width: e ? e * 2 : void 0
  } : { height: o, width: s }, y = i ? {
    height: e ? e * 2 : void 0,
    width: e ? e * 2 + l : void 0
  } : { height: o, width: s ? s + l : void 0 }, w = N(() => {
    if (i && n && e) {
      const t = Math.min(n, e);
      return {
        height: t * 2,
        left: e - t + "px",
        top: e - t + "px",
        width: t * 2
      };
    } else
      return { display: "none" };
  }, [i, n, e]);
  return /* @__PURE__ */ S(
    "div",
    {
      className: k(d.container, c),
      style: R,
      "data-qd-test": r["data-qd-test"],
      children: [
        /* @__PURE__ */ a("div", { className: d.background, style: y }),
        /* @__PURE__ */ a("div", { className: d.innerCircle, style: w })
      ]
    }
  );
});
export {
  O as Skeleton
};
